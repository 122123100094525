function Nav(){
    return (
        <div className="Nav-Bar">
            <nav id="person-panel" className="Nav-Title">
                <p className="panel-heading">
                New Persona
                </p>
            </nav>
        </div>
    );
}

export default Nav;